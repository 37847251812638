import React from 'react';
import CookieLayout from '../components/CookieLayout';
import SEO from '../components/Seo';
import LandingBanner from '../components/LandingBanner/LandingBanner';

const CookiePage = () => {
  return (
    <CookieLayout>
      <SEO title="Home" />
      <LandingBanner title="Cookie Policy" alt />
      <section className="internal-page__section">
        Cookie Policy for W&amp;W Asset Management Ireland DAC This is the Cookie Policy for W&amp;W
        Asset Management Dublin DAC, accessible from https://ww-amd.com
        <h2>What Are Cookies</h2>
        <p>
          As is common practice with almost all professional websites this site uses cookies, which
          are tiny files that are downloaded to your computer, to improve your experience. This page
          describes what information they gather, how we use it and why we sometimes need to store
          these cookies. We will also share how you can prevent these cookies from being stored
          however this may downgrade or 'break' certain elements of the sites functionality.
        </p>
        <p>
          For more general information on cookies, please read{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.cookieconsent.com/what-are-cookies/"
          >
            "What Are Cookies"
          </a>
          .
        </p>
        <h2>How We Use Cookies</h2>
        <p>
          We use cookies for a variety of reasons detailed below. We have enabled an option for
          users to enable / decline enabling cookies. It is recommended that you leave on all
          cookies if you are not sure whether you need them or not in case they are used to provide
          a service that you use.
        </p>
        <h2>Disabling Cookies</h2>
        <p>
          You can adjust your cookie preferences for this site clicking on the "Cookie Management"
          link in the footer. Be aware that disabling all cookies will affect the functionality of
          this and many other websites that you visit. Disabling cookies will usually result in also
          disabling certain functionality and features of the this site.
        </p>
        <h2>The Cookies We Set</h2>
        <ul>
          <li>
            <p>
              Site preferences cookies
              <br />
              <br />
              In order to provide you with a great experience on this site we provide the
              functionality to set your preferences for how this site runs when you use it. In order
              to remember your preferences we need to set cookies so that this information can be
              called whenever you interact with a page is affected by your preferences.
            </p>
          </li>
        </ul>
        <h2>Third Party Cookies</h2>
        <p>
          In some special cases we also use cookies provided by trusted third parties. The following
          section details which third party cookies you might encounter through this site.
        </p>
        <ul>
          <li>
            <p>
              This site uses Google Analytics which is one of the most widespread and trusted
              analytics solution on the web for helping us to understand how you use the site and
              ways that we can improve your experience. These cookies may track things such as how
              long you spend on the site and the pages that you visit so we can continue to produce
              engaging content.
            </p>
            <p>
              For more information on Google Analytics cookies, see the official Google Analytics
              page.
            </p>
          </li>
        </ul>
        <h2>More Information</h2>
        <p>
          Hopefully that has clarified things for you and as was previously mentioned if there is
          something that you aren't sure whether you need or not it's usually safer to leave cookies
          enabled in case it does interact with one of the features you use on our site.
        </p>
        <p>
          However if you are still looking for more information then you can contact us through one
          of our preferred contact methods:
        </p>
        <ul>
          <li>
            Email: <a href="mailto:amd@ww-dublin.com">amd@ww-dublin.com</a>
          </li>
        </ul>
      </section>
    </CookieLayout>
  );
};

export default CookiePage;
